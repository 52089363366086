import { useState } from 'react';
import { list } from "./data";
import './assets/styles/main.css';
import { ReactComponent as Checkmark }from './assets/styles/handdrawn.css/assets/icons/check.svg';
import { ReactComponent as Tree } from './assets/images/christmas-tree-3.svg';
import { ReactComponent as Present } from './assets/images/present-1.svg';
import { ReactComponent as Remove } from './assets/styles/handdrawn.css/assets/icons/times.svg';
import { ReactComponent as Truck } from './assets/styles/handdrawn.css/assets/icons/truck.svg';

function generateId () {
  return '_' + Math.random().toString(36).substr(2, 9);
}

function App() {

  const [ value, setValue ] = useState('');
  const [ todos, setTodos ] = useState(list);
  const [ removeItems, setRemoveItems ] = useState(false);
  
  const handleSubmit = () => {
    if (!value) return;
    setTodos((todos) => todos.concat({
      text: value,
      id: generateId()
    }))
    setValue('')
  }
  
  const setCompleted = (id) => {
    const completed = todos.map( todo => {
      return todo.id === id ? { ...todo, isCompleted: !todo.isCompleted } : { ...todo};
    });
    setTodos(completed);
  }

  const editList = () => {
    setRemoveItems(!removeItems)
  }

  const removeTodo = (id) => {
    setTodos((todos) => todos.filter((t) => t.id !== id))
    if(todos.length <= 1) setRemoveItems(false)
  }

  return (
    <>
      <div className={'notepad'}>
        <header>
          <h1>Booker's X-Mas Wish List...</h1>
          <div className={'add-item-controls'}>
            <input 
              type="text" 
              value={value}
              className={'add-item-input'}
              onChange={(e) => setValue(e.target.value)}
            />
            <button onClick={handleSubmit} className={'add-btn'}>
              + add
            </button>
          </div>
          <div className={'top-icon'}>
            <Tree className={'tree-icon'} />
          </div>
        </header>
        <div className={!todos.length ? 'list empty-list' : 'list'}>
          <main>
            {!todos.length ? (
            <>
              <p>Not even a new truck on Booker's list!?!?</p>
              <Truck className={'truck-icon'} />
            </>
            ) : (
            <ul className={'wish-list'}>
              {todos.map(({ text, id, isCompleted }) => (
                <li key={id} id={id}>
                  <span className={isCompleted ? 'completed item-text' : 'item-text'} onClick={() => setCompleted(id)}>
                    {text}
                    {isCompleted && <Checkmark className={'checkmark-icon'} />}
                  </span>
                  {removeItems && 
                    <span className={'remove-item'} onClick={() => removeTodo(id)}>
                      <Remove className={'remove-icon'} />
                  </span>}
                </li>
              ))}
            </ul> )}
          </main>
          <footer>
            {todos.length ? (
            <div className={'grid-cell'}><button className={'remove-items-btn'} onClick={editList}>
              {!removeItems ? 'x remove items' : 'cancel'}
            </button> </div>) : null }
            <div className={'grid-cell icon'}>
              <Present className={'present-icon'} />
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default App;
