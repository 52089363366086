function generateId () {
    return '_' + Math.random().toString(36).substr(2, 9);
}

export const list = [
    { text: 'Bulldozer', isCompleted: true, id: generateId() },
    { text: 'Crane', isCompleted: true, id: generateId() },
    { text: 'Lite Brite', isCompleted: false, id: generateId() },
    { text: 'Air hockey table', isCompleted: false, id: generateId() },
    { text: 'Fort', isCompleted: false, id: generateId() },
    { text: 'Combine harvester', isCompleted: false, id: generateId() },
    { text: 'A flatbed truck with a truck on the back', isCompleted: false, id: generateId() },
    { text: 'Tractor', isCompleted: false, id: generateId() },
    { text: 'Trucks', isCompleted: false, id: generateId() }
]